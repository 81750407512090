






































































































import { PageBase } from '@/core/models/shared';
import { EmpresaService } from '@/core/services/geral';
import { CartaRastreabilidadeService } from '@/core/services/residuo';
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DetalharMedicao extends PageBase {

  item: any = {};
  cartaRastreabilidadeService: CartaRastreabilidadeService = new CartaRastreabilidadeService();
  overlay: boolean = false;

  cartaRastreabilidadeAutorizacao: boolean = false

  filtro: any = {
    id: null,
    layoutId: null
  }

  created() {
    this.filtro.id = Number(this.$route.params.id);
    this.filtro.layoutId = Number(this.$route.query.layoutId);
  }

  mounted(){

    this.overlay = true;

    this.cartaRastreabilidadeService.Detalhar(this.filtro.id).then(
      res=>{
        this.item = res.data;
        new EmpresaService().ObterPorId(this.app.empresaId, "Configuracao").then(
          res => {
            var empresa = res.data;
            this.cartaRastreabilidadeAutorizacao = empresa.configuracao.cartaRastreabilidadeAutorizacao
          }
        )
      },
      err => {
        if (err.response.status == 400) {
          this.$swal("Aviso", err.response.data, "warning");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() =>{
      this.overlay = false;
    })

  }

  Validar(){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja APROVAR o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.cartaRastreabilidadeService.Aprovar(this.item.id).then(
          res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
        )
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then((result) => {
      if(result.value) {
        this.$swal("Aviso", result.value, "success");
        window.location.reload();
      }
    })
  }
  
  TextoMarcaDagua(){
    return this.item.situacaoId == 3 ? 'Cancelado' : '';
  }

  Imprimir(){
    window.print();
  }

  Fechar(){
    window.close();
  }

}

